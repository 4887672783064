



















































































































































































































































import Vue from "vue";
import store from "@/store";
import router from "@/router";
import svgCalendar from "@/components/svg/svg-mini-calendar.vue";
import svgMessage from "@/components/svg/svg-message.vue";
import MgImage from "@/components/shared/MgImage.vue";
import {
    AttendeeDataObject,
    AttendeeSearchResult,
    IPageOptions
} from "@/types/interfaces";

type attendeeTypeDefinition = AttendeeDataObject | AttendeeSearchResult;
type pageOptionTypeDefinition = IPageOptions;

export default Vue.extend({
    props: {
        data: {
            type: Object,
            required: true,
            default() {
                return [];
            }
        },
        isMeetingRequest: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: ""
        },
        layout: {
            type: String,
            default: "column"
        },
        // The Livestream version needs to only have the message button and that button behaves differently.
        isLivestreamVersion: {
            type: Boolean,
            default: false
        },
        hideButtons: {
            type: Boolean,
            default: false
        }
    },

    components: {
        svgCalendar,
        svgMessage,
        MgImage
    },

    computed: {
        hasAttendeeProfilePage() {
            return store.getters.layoutOptions.hasAttendeeProfilePage;
        },
        usesCreateActivityControl() {
            const optionChecker = store.getters.isPageOptionActiveInEnv;
            return optionChecker("createActivity", "isActive");
        },
        pageOptions(): pageOptionTypeDefinition {
            return store.getters.getPageOptions("attendees");
        },
        hasData(): boolean {
            const data = this.data as attendeeTypeDefinition;

            const hasSomeData = Boolean(
                data.firstName || data.lastName || data.name
            );

            return data && hasSomeData;
        },
        showTitle() {
            // We want this to default to true, so check if the key exists
            const pageOptions = this.pageOptions as pageOptionTypeDefinition;

            return "showAttendeeTitles" in pageOptions
                ? pageOptions.showAttendeeTitles
                : true;
        },
        addCityStateToOrgName(): boolean {
            const pageOptions = this.pageOptions as pageOptionTypeDefinition;

            return pageOptions.addCityStateToOrgName
                ? pageOptions.addCityStateToOrgName
                : false;
        },
    
        shouldShowCountry(): boolean | string {
            const data = this.data as attendeeTypeDefinition;
            const pageOptions = this.pageOptions as pageOptionTypeDefinition;

            if (!data.country) {
                return false;
            }

            if (!pageOptions.hideCountryIfUS) {
                return true;
            }

            return data.country !== "United States";
        },
        activityPageOptions() {
            const returnValue = store.getters.getPageOptions("createActivity");

            return returnValue;
        },
        buttonPlaceholder(): string {
            if (this.data.excludeFromAppointments) {
                return "This user is not accepting invitations.";
            } else {
                const activityPageOptions = this
                    .activityPageOptions as IPageOptions;
                return activityPageOptions.textHeader
                    ? activityPageOptions.textHeader
                    : "Request a Meeting";
            }
        },
        avatarSrc() {
            let returnValue = "";
            const data = this.data as attendeeTypeDefinition;
            const images = data.images?.avatar;

            if (images && (images["640x640"] || images["320x320"])) {
                if (images["320x320"]) {
                    returnValue = images["320x320"];
                } else {
                    returnValue = images["640x640"];
                }
            }

            return returnValue;
        },

        avatarSrcSet() {
            const data = this.data as attendeeTypeDefinition;
            let returnPath = "";
            const set = [];

            if (data.images?.avatar) {
                const urls = data.images.avatar;
                const imgSm = urls["320x320"];
                const imgLg = urls["640x640"];

                if (imgSm) {
                    set.push(`${imgSm} 600w`);
                }

                if (imgLg) {
                    set.push(`${imgLg} 2048w`);
                }

                returnPath = set.join(",");
            }

            return returnPath;
        },
        sendMessageText(): string {
            return this.data.excludeFromMessaging
                ? "This user does not wish to receive messages."
                : "Send a message";
        }
    },
    methods: {
        attendeeListFields(str: string) {
            return this.pageOptions.attendeeListFields?.find((item)=> {
                return item == str
            })
        },

        openMessage() {
            const data = this.data as attendeeTypeDefinition;

            const attendeeId: string = data.attendeeId
                ? data.attendeeId
                : data.id
                ? data.id
                : "";

            const attendeeData = {
                attendeeId: attendeeId,
                firstName: data.firstName ? data.firstName : "",
                lastName: data.lastName ? data.lastName : "",
                email: data.email ? data.email : "",
                companyName: data.companyName ? data.companyName : ""
            };

            if (this.isLivestreamVersion) {
                this.$emit("sendLivestreamMessage", attendeeData);
            } else {
                console.log(attendeeData);
                router.push({
                    name: "Message-Center",
                    params: { ...attendeeData }
                });
            }
        },

        requestMeeting(attendee: AttendeeDataObject | AttendeeSearchResult) {
            const attendeeData = JSON.stringify(attendee);

            router.push({
                name: "CreateActivity",
                params: {
                    attendee: attendeeData
                }
            });
        },

        getModifiedOrgName(
            attendee: AttendeeDataObject | AttendeeSearchResult
        ) {
            const pageOptions = this.pageOptions as pageOptionTypeDefinition;
            let companyName = attendee.companyName;

            if (pageOptions.useSchoolForCompanyName && attendee.school) {
                companyName = attendee.school;
            }

            return pageOptions.addCityStateToOrgName
                ? [companyName, attendee.city, attendee.state]
                      .filter((item) => item && item != "")
                      .join(", ")
                : companyName;
        },

        getDisplayName() {
            const pageOptions = this.pageOptions as pageOptionTypeDefinition;

            const data = this.data as attendeeTypeDefinition;

            let parts = [];

            if (pageOptions.useMiddleInitial) {
                parts = [data.firstName, data.MI, data.lastName];
            } else {
                parts = [data.firstName, data.lastName];
            }

            if (pageOptions.usePrefixAndSuffix) {
                parts.unshift(data.prefix);
            }

            let name = parts
                .filter((part) => {
                    return part && part.trim() != "";
                })
                .join(" ");

            if (
                pageOptions.usePrefixAndSuffix &&
                data.suffix &&
                data.suffix.trim() != ""
            ) {
                name += `, ${data.suffix}`;
            }

            return name;
        },

        logoColor() {
            const hue =
                "hsl(" +
                360 * Math.random() +
                "," +
                (25 + 70 * Math.random()) +
                "%," +
                (85 + 10 * Math.random()) +
                "%)";
            return { background: `${hue}` };
        }
    }
});
